
  .SideDrawer {
    transform: translateX(200px);
    transition: transform 0.3s ease-in-out;
  }
  
  .SideDrawer.open {
    transform: translateX(0);
  }
  
  .SideDrawer button {
    display: block;
    width: 100%;
    border: none; 
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  
  .MainContent {
    margin-right: 20px;
  }