.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    z-index: 10;
    top: 100%;
    color: black;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  
  .dropdown.active .dropdown-menu {
    display: block;
  }
  
  .dropdown-content a {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #374151;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
  .dropdown-content a:hover,
  .dropdown-content a:focus {
    background-color: #f3f4f6;
  }
  

  